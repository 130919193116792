import React from "react"

interface Props {
  title: string
  thumbnail: string
}

const Product: React.FC<Props> = ({ title, thumbnail, children }) => (
  <div className="card" style={{ height: "100%" }}>
    <div className="card-image">
      <figure className="image is-4by3">
        <img src={thumbnail} alt={title} />
      </figure>
    </div>
    <div className="card-content">
      <div className="content">
        <p className="title is-size-5">{title}</p>
        <div className="is-size-7">{children}</div>
      </div>
    </div>
  </div>
)

export default Product
