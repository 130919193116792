import React from "react"
import Layout from "../components/layout"
import InfoSection from "../components/InfoSection"
import Product from "../components/Product"

const products = [
  {
    title: "BT (Body Thread)",
    thumbnail: "/products/bt.png",
    content: (
      <>
        <p>Open Thread Type for reducing load during placing</p>
        <p>Taper-Straight Design for stable placement</p>
        <p>Easy entry due to Sharp Apex</p>
        <p>Enhanced Self Tapping function by cutting</p>
      </>
    ),
  },
  {
    title: "BT Special Length",
    thumbnail: "/products/special-length-bt.png",
    content: (
      <>
        <p>Open Thread Type for reducing load during placing</p>
        <p>Taper-Straight Design for stable placement</p>
        <p>Easy entry due to Sharp Apex</p>
        <p>Enhanced Self Tapping function by cutting</p>
      </>
    ),
  },
  {
    title: "MT (Micro Thread)",
    thumbnail: "/products/mt.png",
    content: (
      <>
        <p>Micro double Thread</p>
        <p>Open Thread Type for reducing load during placing</p>
        <p>Taper-Straight Design for stable placement</p>
        <p>Easy entry due to Sharp Apex</p>
        <p>Enhanced Self Tapping function by cutting</p>
      </>
    ),
  },
  {
    title: "MT Special Length",
    thumbnail: "/products/special-length-mt.png",
    content: (
      <>
        <p>Micro double Thread</p>
        <p>Open Thread Type for reducing load during placing</p>
        <p>Taper-Straight Design for stable placement</p>
        <p>Easy entry due to Sharp Apex</p>
        <p>Enhanced Self Tapping function by cutting</p>
      </>
    ),
  },
  {
    title: "Cover Screw",
    thumbnail: "/products/cover-screw.png",
    content: (
      <>
        <p>Uses a Hand Driver (1.2HEX)</p>
        <p>Recommend Torque : 5~8Ncm</p>
      </>
    ),
  },
]

const ProductPage: React.FC = () => (
  <Layout>
    <InfoSection title="Produkt">
      <div className="rwd-center">
        <p>- powierzchnia S.L.A.</p>
        <p>
          -wewnętrzne połączenie sześciokątne do uszczelniania stożkowego:
          11(znaczek stopnia)Morse Taper&amp; 2.5mm Hex
        </p>
        <p>-prosty-zwężający się korpus dla łatwego umieszczenia </p>
        <p>-unikalny gwint makro dla zminimalizowania siły ścinającej </p>
        <p>-wierzchołek z ostrzem tnącym</p>
        <p>-wystarczająco gruba ściana – wieksza wytrzymałość na ścisk </p>
      </div>
      <div className="columns">
        {products.map(product => (
          <div className="column" key={product.thumbnail}>
            <Product title={product.title} thumbnail={product.thumbnail}>
              {product.content}
            </Product>
          </div>
        ))}
      </div>
    </InfoSection>
  </Layout>
)

export default ProductPage
